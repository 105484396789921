import APEDA from "../../assets/img/certificate/apeda.png";
import DGFD from "../../assets/img/certificate/dgft.jpg";
import FDA from "../../assets/img/certificate/fda.png";
import FICCI from "../../assets/img/certificate/ficci.jpg";
import FIEO from "../../assets/img/certificate/fieo.jpg";
import GCCI from "../../assets/img/certificate/gcci.png";
import GMP from "../../assets/img/certificate/gmp.png";
import GST from "../../assets/img/certificate/gst.jpg";
import HALAL from "../../assets/img/certificate/halal.png";
import MSME from "../../assets/img/certificate/msme.png";
import SPICE from "../../assets/img/certificate/spice.png";
import UDYOGAADHAR from "../../assets/img/certificate/udyogaadhaar.png";

const certificates = [
  APEDA,
  DGFD,
  FDA,
  FICCI,
  FIEO,
  GCCI,
  GMP,
  GST,
  HALAL,
  MSME,
  SPICE,
  UDYOGAADHAR,
];

const Certification = () => {
  return (
    <div className="row section-title" style={{ margin: "10rem 3rem 5rem" }}>
      <p style={{ marginBottom: "1rem" }}> We are certified </p>
      {certificates?.map((image) => (
        <div
          key={image}
          className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
          style={{ marginBottom: "2rem" }}
        >
          <div className="card">
            <div className="card-body">
              <img
                src={image}
                alt={image}
                style={{ width: "100%", height: "auto", scale: "0.7" }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Certification;
