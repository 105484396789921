import { useLocation, Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";

const DesktopHeader = () => {
  const location = useLocation();
  const path = location?.pathname;
  const active = { color: "#ffc451" };

  return (
    <header
      id="header"
      className="header d-flex align-items-center fixed-top bg-dark"
    >
      <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
        <img
          src={Logo}
          alt={Logo}
          style={{ width: "10rem" }}
          className="logo d-flex align-items-center me-auto"
        />
        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <Link
                to="/home"
                style={path === "/home" || path === "/" ? active : {}}
              >
                {" "}
                Home{" "}
              </Link>
            </li>
            <li className="dropdown">
              <a href="javascript:void(0)">
                <span>About</span>{" "}
                <i className="bi bi-chevron-down toggle-dropdown" />
              </a>
              <ul>
                <Link to="/company"> Company</Link>
                <Link to="/certification"> Certification</Link>
                <Link to="/key-person"> Key Person</Link>
              </ul>
            </li>

            <li>
              <Link
                to="/sourcing-agent"
                style={path === "/sourcing-agent" ? active : {}}
              >
                {" "}
                Sourcing Agent
              </Link>
            </li>

            <li>
              <Link to="/gallery" style={path === "/gallery" ? active : {}}>
                {" "}
                Gallery
              </Link>
            </li>

            <li>
              <Link to="/contact" style={path === "/contact" ? active : {}}>
                {" "}
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default DesktopHeader;
