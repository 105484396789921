/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Logo from "../../assets/img/logofooter.png";

const Footer = () => {
  return (
    <footer id="footer" className="footer dark-background">
      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-4 footer-about">
              <img
                src={Logo}
                alt={Logo}
                style={{ width: "15rem", height: "auto" }}
                className="logo d-flex align-items-center me-auto"
              />
              <div className="footer-contact pt-3">
                <p>1st Floor, Everest Plaza, </p>
                <p>Ganjhakhet, Gandhibagh, Nagpur-440002</p>
                <p className="mt-3">
                  <strong>Phone:</strong> <span>+91 8484086082</span>
                </p>
                <p>
                  <strong>Email:</strong> <span>info@theoneinfinity.com</span>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4"></div>
            <div className="col-lg-4 col-md-4 footer-links">
              <div className="social-links d-flex">
                <a href>
                  <i className="bi bi-twitter-x" />
                </a>
                <a href>
                  <i className="bi bi-facebook" />
                </a>
                <a href>
                  <i className="bi bi-instagram" />
                </a>
                <a href>
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container text-center">
          <p>
            © <span>Copyright</span>{" "}
            <strong className="px-1 sitename">
              One Infinity International
            </strong>{" "}
            <span>All Rights Reserved</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
