import APEDA from "../../assets/img/certificate/apeda.png";
import DGFD from "../../assets/img/certificate/dgft.jpg";
import FDA from "../../assets/img/certificate/fda.png";
import FICCI from "../../assets/img/certificate/ficci.jpg";
import FIEO from "../../assets/img/certificate/fieo.jpg";
import GCCI from "../../assets/img/certificate/gcci.png";
import GMP from "../../assets/img/certificate/gmp.png";
import GST from "../../assets/img/certificate/gst.jpg";
import HALAL from "../../assets/img/certificate/halal.png";
import MSME from "../../assets/img/certificate/msme.png";
import SPICE from "../../assets/img/certificate/spice.png";
import UDYOGAADHAR from "../../assets/img/certificate/udyogaadhaar.png";
import Carousel from "react-elastic-carousel";

const certificates = [
  APEDA,
  DGFD,
  FDA,
  FICCI,
  FIEO,
  GCCI,
  GMP,
  GST,
  HALAL,
  MSME,
  SPICE,
  UDYOGAADHAR,
];

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const Certificate = () => {
  return (
    <section
      id="services"
      className="services section"
      style={{ backgroundColor: "#758694" }}
    >
      <div className="container section-title" data-aos="fade-up">
        <h2 style={{ color: "#fff" }}>Certification</h2>
        <p style={{ color: "#fff" }}>Membership & Certification</p>
      </div>

      <div className="container">
        <Carousel breakPoints={breakPoints}>
          {certificates.map((certificate) => (
            <div key={certificate}>
              <img
                src={certificate}
                alt={certificate}
                style={{ width: "100%", height: "auto", scale: "0.7" }}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Certificate;
